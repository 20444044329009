import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col, Card as RBCard } from "react-bootstrap"
import Slider from "react-slick"

import Quote from "../images/quote-card-bg.svg"
import Star from "../images/star.svg"

// Avatars
import Avatar1Src from "../images/avatars/avatar-1.jpeg"
import Avatar2Src from "../images/avatars/avatar-2.jpeg"
import Avatar3Src from "../images/avatars/avatar-3.jpeg"
import Avatar4Src from "../images/avatars/avatar-4.jpeg"

const SliderStyled = styled(Slider)`
  width: 485px;
  margin-left: auto;
  margin-right: -1rem;
  @media(min-width: 768px) and (max-width: 991.98px) {
    width: 345px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Card = styled(RBCard)`
  min-height: 248px;
`

const Avatar = styled.img`
  width: 64px;
  height: 64px;
`

const ResponsiveDots = styled.ul`
  @media (max-width: 767px) {
    text-align: left;
    padding: 0 3rem;
  }
`

const Testimonials = () => {
  const { t } = useTranslation()

  const testimonials = [
    {
      name: "Maria Gonzalez",
      avatar: Avatar1Src,
      message: t("testimonial_1")
    },
    {
      name: "Rocio Martínez",
      avatar: Avatar2Src,
      message: t("testimonial_2")
    },
    {
      name: "Sara Rodríguez",
      avatar: Avatar3Src,
      message: t("testimonial_3")
    },
    {
      name: "Sergio Herrera",
      avatar: Avatar4Src,
      message: t("testimonial_4")
    }
  ]

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <ResponsiveDots>{dots}</ResponsiveDots>
    )
  }

  return (
    <div className="py-4 py-lg-8">
      <Container className="gx-0 gx-md-3">
        <Row className="gx-0 gx-md-5">
          <Col className="d-flex flex-column justify-content-center gx-5">
            <h1>{t("testimonials_title")}</h1>
            <h3>{t("testimonials_subtitle")}</h3>
          </Col>
          
          <Col>
            <SliderStyled {...settings}>
              {testimonials.map((item, index) => (
                <div key={index} className="px-2">
                  <Card className="my-3 mb-5 my-md-5 mx-2 p-3">
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <Avatar src={item.avatar} className="rounded-circle" />
                        <div className="flex-grow-1 ms-3">
                          <h3 className="mb-1">{item.name}</h3>
                          {(new Array(5).fill(undefined)).map((_, index) => <Star key={index} className="me-1" />)}
                        </div>
                        <Quote className="d-none d-md-block" />
                      </div>
                      <div className="fw-light mt-4">
                        {item.message}
                      </div>
                      <div className="d-flex d-md-none justify-content-end mt-3">
                        <Quote />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </SliderStyled>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Testimonials
