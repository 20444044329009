import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { Container, Row, Col, Button } from "react-bootstrap"

import Ambetter from "../images/co-ambetter.png"
import Fridays from "../images/co-fridays.png"
// import Bright from "../images/co-bright.png"
import Cigna from "../images/co-cigna.png"
import Oscar from "../images/co-oscar.png"
import FloridaBlue from "../images/co-florida.png"
import BlueCross from "../images/co-bluecross.png"
import Aetna from "../images/co-aetna.png"
import UnitedHealthcare from "../images/co-united.png"
import Amerihealth from "../images/co-amerihealt-caritas.png"
import Anthem from "../images/co-anthem.png"
import BestLife from "../images/co-best-life.png"
import CommunityHealthChoice from "../images/co-community-health-choice.png"
import Caresource from "../images/co-caresource.png"
import Kaiser from "../images/co-kaiser-permanente.png"
import Molina from "../images/co-molina.png"
import Presbyterian from "../images/co-presbyterian.png"
import Optima from "../images/co-optima.png"

const PartnerImage = styled.img`
  height: 55px;
`

const PartnersCarousel = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    slidesToScroll: 1,
    variableWidth: true,
  }

  const partners = [
    Ambetter,
    Fridays,
    // Bright,
    Cigna,
    Oscar,
    FloridaBlue,
    BlueCross,
    Aetna,
    UnitedHealthcare,
    Amerihealth,
    Anthem,
    BestLife,
    CommunityHealthChoice,
    Caresource,
    Kaiser,
    Molina,
    Presbyterian,
    Optima
  ]

  return (
    <Container>
      <Slider {...settings}>
        {partners.map((item, index) => (
          <div key={index} className="pe-5">
            <PartnerImage src={item} />
          </div>
        ))}
      </Slider>
    </Container>
  )
}

export default PartnersCarousel
