import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import MainCarousel from "../components/mainCarousel"
// import BenefitsCarousel from "../components/benefitsCarousel"
import QuoteForm from "../components/quoteForm"
import FaqAccordion from "../components/faqAccordion"
import Testimonials from "../components/testimonials"
import StockImage from "../components/stockImage"
import PartnersCarousel from "../components/partnersCarousel"


import FamilySrc from "../images/stock-image-1.jpg"
import TechSupport from "../images/stock-image-19.jpg"
import TechSupportTablet from "../images/stock-image-19-tablet.jpg"
import Map from "../images/usa-map.png"
import BenefitsBannerSrc from "../images/stock-image-20.jpg"

const InfoSection = styled.div`
  background-color: ${props => props.theme.colors["body-color"]};
  color: #ffffff;
`

const MapImage = styled.img`
  width: 100%;
  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
`

const BenefitsBanner = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
`

const IndexPage = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  const steps = [
    {
      title: t("step_1_title"),
      description: t("step_1_description"),
    },
    {
      title: t("step_2_title"),
      description: t("step_2_description"),
    },
    {
      title: t("step_3_title"),
      description: t("step_3_description"),
    }
  ]

  return (
    <Layout>
      <SEO title="Apremia" />

      <MainCarousel />

      <InfoSection className="py-md-4 py-lg-6">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col xs="12" lg={{ span: 6, order: 0 }}>
              <StockImage src={breakpoints.md ? TechSupportTablet : TechSupport} />
            </Col>
            <Col
              xs="12"
              lg={{ span: 6, order: 1 }}
              className="d-flex flex-column justify-content-center equalize-padding-x px-lg-6 pb-4 pb-md-0"
            >
              <span className="fw-light fs-3">
                <Trans i18nKey="home_info">
                  En APREMIA encontrarás un equipo de especialistas entrenados y con experiencia que te ayudarán a encontrar una póliza que se ajuste a tus necesidades y a tu presupuesto, así como brindarte el soporte que se requiere para hacer un uso adecuado y oportuno de tu seguro de salud, sin importar si es un trámite de rutina o una emergencia médica.
                </Trans>
              </span>
            </Col>
          </Row>
        </Container>
      </InfoSection>

      {/* ----- Partners ----- */}
      <div className="bg-white py-5 py-lg-6">
        <Container className="equalize-padding-x gx-0 gx-md-3">
          <h1 className="pb-lg-4">{t("partners_heading")}</h1>
        </Container>
        <PartnersCarousel />
      </div>
      {/* ----- Partners (end) ----- */}

      {/* ----- Map ----- */}
      <div className="py-5 py-lg-6">
        <Container className="equalize-padding-x gx-0 gx-md-3">
          <h1>{t("map_heading")}</h1>
          <Row>
            <Col lg={7}>
              <MapImage src={Map} />
            </Col>
            <Col className="d-flex flex-column justify-content-center ps-lg-5">
              <ul className="ul ul-column-2 fw-light">
                <li>Alabama (AL)</li>
                <li>Arizona (AZ)</li>
                <li>Arkansas (AR)</li>
                <li>California (CA)</li>
                <li>Carolina del Norte (NC)</li>
                <li>Carolina del Sur (SC)</li>
                <li>Colorado (CO)</li>
                <li>Florida (FL)</li>
                <li>Georgia (GA)</li>
                <li>Illinois (IL)</li>
                <li>Indiana (IN)</li>
                <li>Iowa (IA)</li>
                <li>Kansas (KS)</li>
                <li>Louisiana (LA)</li>
                <li>Maryland (MD)</li>
                <li>Míchigan (MI)</li>
                <li>Misisipi (MS)</li>
                <li>Misuri (MO)</li>
                <li>Nebraska (NE)</li>
                <li>Nueva Jersey (NJ)</li>
                <li>Nuevo México (NM)</li>
                <li>Ohio (OH)</li>
                <li>Oklahoma (OK)</li>
                <li>Tennessee (TN)</li>
                <li>Texas (TX)</li>
                <li>Utah (UT)</li>
                <li>Virginia (VA)</li>
                <li>Wisconsin (WI)</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ----- Map (end) ----- */}

      {/* ----- Benefits ----- */}
      {/* <div className="py-5 py-lg-6">
        <Container>
          <h1 className="pb-lg-4">{t("benefits_heading")}</h1>
        </Container>
        <BenefitsCarousel />
      </div> */}
      <div className="bg-white pb-5">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col>
              <BenefitsBanner src={BenefitsBannerSrc} />
            </Col>
          </Row>
          <Row className="gx-0 gx-md-3 mt-4 mt-md-5">
            <Col xs="12" md="8" className="equalize-padding-x mx-auto">
              <h1>{t("benefits_heading")}</h1>
              <ul className="ul ul-normal">
                {(new Array(9).fill(undefined)).map((_, index) => (
                  <li key={index} className="mb-3">
                    {t(`benefit_${index}`)}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ----- Benefits (end) ----- */}

      {/* ----- How does it work ----- */}
      <div className="py-5 py-lg-6">
        <Container className="gx-0 gx-md-3">
          <h1 className="equalize-padding-x pb-lg-4">{t("how_work")}</h1>
          <Row className="gx-0 gx-lg-5 h-100">
            <Col xs="12" lg>
              <StockImage src={FamilySrc} />
            </Col>
            <Col
              xs="12"
              lg
              className="d-flex flex-column align-items-start justify-content-center"
            >
              {steps.map((item, index) => (
                <div className="step" key={index}>
                  <div className="step-badge">{index + 1}</div>
                  <h3 className="step-heading">{item.title}</h3>
                  <span className="fw-light">{item.description}</span>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ----- How does it work (end) ----- */}

      <QuoteForm />

      <FaqAccordion />

      <Testimonials />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
