import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container, Row, Col, Button } from "react-bootstrap"

import { APREMIA_PHONE } from "../params"

import Family from "../images/slides/family.jpg"
import CallCenter from "../images/slides/call-center.jpg"
import SeniorPatient from "../images/slides/senior-patient.jpg"
import Coins from "../images/slides/coins.jpg"
import Insurance from "../images/slides/insurance.jpg"
import Sign from "../images/slides/sign.jpg"

const CarouselImage = styled.img`
  width: 536px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
`

const MainCarousel = () => {
  const { t } = useTranslation()

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div>
        <div className="mx-3 mx-md-auto">
          <ul className="d-flex p-0 m-0">{dots}</ul>
        </div>
      </div>
    ),
  }

  const slides = [
    {
      title: t("carousel_1_title"),
      subtitle: t("carousel_1_subtitle"),
      label: t("carousel_1_title_cta"),
      href: "https://portal.apremia.com/es/quote/about_me",
      image: Family,
    },
    {
      title: t("carousel_2_title"),
      subtitle: t("carousel_2_subtitle"),
      label: t("call_now"),
      href: `tel:${APREMIA_PHONE}`,
      image: CallCenter,
    },
    /* {
      title: t("carousel_3_title"),
      subtitle: t("carousel_3_subtitle"),
      label: t("more_info"),
      href: "#quote-form",
      image: SeniorPatient,
    }, */
    {
      title: t("carousel_4_title"),
      subtitle: t("carousel_4_subtitle"),
      label: t("more_info"),
      href: "/accident-insurance/",
      image: Coins,
    },
    {
      title: t("carousel_5_title"),
      subtitle: t("carousel_5_subtitle"),
      label: t("more_info"),
      href: "/employment-insurance/",
      image: Insurance,
    },
    /* {
      title: t("carousel_6_title"),
      subtitle: t("carousel_6_subtitle"),
      label: t("more_info"),
      href: "#quote-form",
      image: Sign,
    }, */
  ]

  return (
    <Container className="gx-0 gx-md-3">
      <Slider className="main-slider" {...settings}>
        {slides.map((item, index) => (
          <div className="d-flex flex-column align-items-center" key={index}>
            <Container className="slide gx-0">
              <Row className="gx-0 h-100">
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 0 }}
                  className="equalize-padding-x d-flex flex-column align-items-start justify-content-center pe-md-4"
                >
                  <h1>{item.title}</h1>
                  <h3>{item.subtitle}</h3>
                  <Button variant="primary" href={item.href}>
                    {item.label}
                  </Button>
                </Col>
                <Col
                  xs={{ span: 12, order: 0 }}
                  md={{ span: 6, order: 1 }}
                  className="d-flex flex-column align-items-end justify-content-center"
                >
                  <CarouselImage src={item.image} />
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      </Slider>
    </Container>
  )
}

export default MainCarousel
