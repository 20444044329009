import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container, Accordion } from "react-bootstrap"

const FaqAccordion = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-white py-5 py-lg-6">
      <Container className="gx-0 gx-md-3">
        <h1 className="equalize-padding-x pb-lg-4">{t("faq")}</h1>

        <div className="px-md-6 px-lg-8">
          <Accordion flush alwaysOpen>
            {(new Array(8).fill(undefined)).map((_, index) => (
              <Accordion.Item key={index} eventKey={index} className="equalize-padding-x">
                <Accordion.Header>
                  <h3 className="d-none d-md-block m-0">{t(`faq_q${index + 1}_title`)}</h3>
                  <h4 className="d-md-none m-0">{t(`faq_q${index + 1}_title`)}</h4>
                </Accordion.Header>
                <Accordion.Body className="render-tags">
                  <div className="pb-5 pe-md-6 fw-light">
                    {t(`faq_q${index + 1}_description`)}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Container>
    </div>
  )
}

export default FaqAccordion
